import React from 'react';
import { withGetScreen } from 'react-getscreen';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import $ from 'jquery';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import toastr from 'toastr';
import '../toastr.css';

const minMintAmount = 1;
const maxMintAmount = 5;
const perTokenAmountEth = 0.04;
class Home extends React.Component {

  responsive = {
    0: {
      items: 1
    },
    768: {
      items: 2
    },
    1170: {
      items: 3
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      mobile: this.props.isMobile(),
      tab: this.props.isTablet(),
      account: this.props.prop.account,
      isEnabled: this.props.prop.isEnabled,
      web3: this.props.prop.web3,
      mintWeb3InstV1: this.props.prop.mintWeb3InstV1,
      mintWeb3InstV2: this.props.prop.mintWeb3InstV2,
      mintAmount: minMintAmount,
      totalTokenAmountEth: perTokenAmountEth * minMintAmount,
      currentEthBal: '-',
      mintedTokensCount: 0,
      remainingCount: 0,
      totalCount: 0,
      isSaleActive: false,
      isClaimActive: false,
      mintedBalanceV1: 0,
      mintedTokensV1: [],
      selectedTokensForClaim: [],
      mintStatus: 'start',
    };

    toastr.options = {
      // positionClass: 'toast-top-full-width',
      hideDuration: 300,
      timeOut: 3000
    }
  }
  componentDidMount() {
    if (this.state.isEnabled) {
      this.loadData();
      this.isSaleActive();
      this.isClaimActived();
      this.getMintedBalanceV1();
    }

    $('html, body').animate({
      scrollTop: 0
    }, 100);
  }

  async loadData() {

    await this.callsABI();
    setTimeout(() => {
      if (this.state.isEnabled) {
        this.loadData()
      }
    }, 5000)
  }

  async callsABI() {
    try {
      this.loadUserEthBalance();
      this.mintedTokens();
      this.totalTokenToBeMinted();
      this.tokenRemainingToBeMinted();
    } catch (e) {
      console.error(e)
    }
  }

  getMintedBalanceV1 = async () => {
    let totalMintedBalanceV1 = await this.state.mintWeb3InstV1.methods.balanceOf(this.state.account).call();
    await this.setState({ mintedBalanceV1: totalMintedBalanceV1 })
    if (totalMintedBalanceV1 > 0) {
      let list = [];
      this.getMintedTokensList(list, totalMintedBalanceV1, 0);
    }
  }

  getMintedTokensList = async (list, totalMintedBalanceV1, index) => {
    if (index <= totalMintedBalanceV1 - 1) {
      let temp = await this.state.mintWeb3InstV1.methods.tokenOfOwnerByIndex(this.state.account, index).call();
      list.push(temp)

      await this.getMintedTokensList(list, totalMintedBalanceV1, index + 1);
    } else if (index > totalMintedBalanceV1 - 1) {
      //set in state
      console.log(list)
      await this.setState({ mintedTokensV1: list });
    }
  }

  async loadUserEthBalance() {
    try {
      let currentEthBal = await this.state.web3.eth.getBalance(this.state.account);
      this.setState({ currentEthBal: this.getFormatedNumber(this.getFormatFrom(Number(currentEthBal)), 1, 6) })
    } catch (e) {
      console.error(e)
    }
  }

  async isSaleActive() {
    try {
      let isSaleActive = await this.state.mintWeb3InstV2.methods.isSaleActive().call();
      this.setState({ isSaleActive: isSaleActive });
    } catch (e) {
      console.error(e);
    }
  }

  async isClaimActived() {
    try {
      let isClaimActive = await this.state.mintWeb3InstV2.methods.isClaimActive().call();
      console.log("isClaimActive", isClaimActive)
      this.setState({ isClaimActive: isClaimActive });
    } catch (e) {
      console.error(e);
    }
  }

  async mintedTokens() {
    try {
      let mintedTokensCount = await this.state.mintWeb3InstV2.methods.minted(this.state.account).call();
      // console.log(mintedTokensCount)
      this.setState({ mintedTokensCount: Number(mintedTokensCount) });
    } catch (e) {
      console.error(e);
    }
  }

  async totalTokenToBeMinted() {
    try {
      let totalCount = await this.state.mintWeb3InstV2.methods.TOTAL_TOKEN_TO_MINT().call();
      // console.log(totalCount)
      this.setState({ totalCount: Number(totalCount) });
    } catch (e) {
      console.error(e);
    }
  }

  async tokenRemainingToBeMinted() {
    try {
      let remainingCount = await this.state.mintWeb3InstV2.methods.tokenRemainingToBeMinted().call();
      // console.log(remainingCount)
      await this.setState({ remainingCount: Number(remainingCount) });
    } catch (e) {
      console.error(e);
    }
  }

  onPlusAmount() {
    if (this.state.mintAmount && this.state.mintAmount < maxMintAmount) {
      if ((this.state.mintAmount + this.state.mintedTokensCount) > maxMintAmount) {
        toastr.error("Max token amount exceeds for your wallet")
        return
      }
      this.setState({ mintAmount: this.state.mintAmount + 1, totalTokenAmountEth: (perTokenAmountEth * (this.state.mintAmount + 1)) })
    }
  }

  onMinusAmount() {
    if (this.state.mintAmount && this.state.mintAmount > minMintAmount) {
      this.setState({ mintAmount: this.state.mintAmount - 1, totalTokenAmountEth: (perTokenAmountEth * (this.state.mintAmount - 1)) })
    }
  }

  async mintSubmit() {
    try {
      if (this.state.isEnabled && !this.state.isConnecting) {

        if (this.state.mintStatus == "inProgress") {
          toastr.error("Transaction is in progress.")
          return;
        }

        if (Number(this.state.mintAmount) <= 0 || Number(this.state.mintAmount) > maxMintAmount) {
          toastr.error("Please select from 1-5 range.")
          return;
        }

        if ((this.state.mintAmount + this.state.mintedTokensCount) > maxMintAmount) {
          toastr.error("Max token amount exceeds for your wallet")
          return;
        }

        await this.setState({ mintStatus: 'inProgress' });
        let totalValue = this.state.mintAmount * 40000000000000000;

        if (Number(this.getFormatFrom(totalValue)) >= Number(this.state.currentEthBal)) {
          await this.setState({ mintStatus: 'start' })
          toastr.error("Insufficient ETH balance.")
          return;
        }

        if (this.state.remainingCount < Number(this.state.mintAmount)) {
          await this.setState({ mintStatus: 'start' })
          toastr.error("Token amount is greater than available to be minted");
          return;
        }

        if (this.state.isSaleActive) {
          await this.state.mintWeb3InstV2.methods.mintToken(Number(this.state.mintAmount)).send({
            from: this.state.account,
            value: totalValue
          }).then(async (result) => {
            await this.setState({ mintStatus: 'start' })
            this.tokenRemainingToBeMinted();
            toastr.success("Your Token have been minted.")
          }).catch(async (error) => {
            // console.log(error)
            await this.setState({ mintStatus: 'start' })
            toastr.error("Mint Failed");
          });
        } else {
          await this.setState({ mintStatus: 'start' })
          toastr.info("Sale not active")
        }

      } else {
        toastr.info("Please connect you'r wallet.")
      }
    } catch (e) {
      toastr.error("Mint Failed due to some error.")
    }

  }

  getFormatTo(amount) {
    return this.state.web3.utils.toWei((amount).toString(), "ether");
  }

  getFormatFrom(amount) {
    return this.state.web3.utils.fromWei((amount).toString(), "ether");
  }

  getFormatedNumber(num, divideBy, fixUpto) {
    if (num == 0) {
      return 0
    }
    return this.getDecimals((num / divideBy).toFixed(fixUpto));
  }

  getDecimals(str) {
    if (str.endsWith('.000000')) {
      return Number(str.replace('.000000', ''));
    } else if (str.endsWith('.00000')) {
      return Number(str.replace('.00000', ''));
    } else if (str.endsWith('.0000')) {
      return Number(str.replace('.0000', ''));
    } else if (str.endsWith('.000')) {
      return Number(str.replace('.000', ''));
    } else if (str.endsWith('.00')) {
      return Number(str.replace('.00', ''));
    } else if (str.endsWith('.0')) {
      return Number(str.replace('.0', ''));
    } else {
      return Number(str)
    }
  }

  componentWillUnmount() {
  }

  isAnyTransactionInProgress() {
    if (this.state.mintStatus == 'inProgress') {
      return true;
    }
    return false;
  }

  getTokenLists() {
    console.log("mintedTokensV1", this.state.mintedTokensV1)
    if (this.state.mintedTokensV1 && this.state.mintedTokensV1.length > 0) {
      return this.state.mintedTokensV1.map((e, key) => {
        return <div className="col-md-6"><div onClick={() => { this.addRemoveFromClaimList(e) }} className={"claim-token " + (this.state.selectedTokensForClaim.indexOf(e) >= 0 ? 'selected-token' : '')} key={key} >{e}</div></div>;
      })
    } else {
      return;
    }
  }

  async addRemoveFromClaimList(tokenId) {
    if (this.state.mintStatus != 'start') {
      return
    }
    let selectedIndex = this.state.selectedTokensForClaim.indexOf(tokenId);
    let currentList = [...this.state.selectedTokensForClaim];
    if (selectedIndex >= 0) {
      currentList.splice(selectedIndex, 1);
      await this.setState({ selectedTokensForClaim: currentList });
      return;
    } else {
      let isClaimed = await this.state.mintWeb3InstV2.methods.claimed(tokenId).call();
      console.log(isClaimed)
      if (!isClaimed) {
        currentList.push(tokenId);
        await this.setState({ selectedTokensForClaim: currentList });
      }
    }
  }

  async claimSubmit() {
    try {
      if (!this.state.selectedTokensForClaim || this.state.selectedTokensForClaim.length <= 0) {
        return;
      }
      if (this.state.isEnabled && !this.state.isConnecting) {

        if (this.state.mintStatus == "inProgress") {
          toastr.error("Transaction is in progress.")
          return;
        }
        if (!this.state.isClaimActive) {
          toastr.error("Claim is not active")
          return;
        }
        await this.setState({ mintStatus: 'inProgress' });
        this.state.mintWeb3InstV2.methods.claim(this.state.selectedTokensForClaim).send({
          from: this.state.account,
        }).then((result) => {
          toastr.success("Claimed Successfully.")
          this.setState({ mintStatus: 'start' });
          this.resetSelection();
          this.getMintedBalanceV1();
        }).catch(async (error) => {
          this.setState({ mintStatus: 'start' })
          toastr.error("Claim Failed");
        });
      } else {
        this.setState({ mintStatus: 'start' })
        toastr.error("Your wallet is not connected yet");
      }
    } catch (e) {
      this.setState({ mintStatus: 'start' })
      toastr.error("Mint Failed due to some error.")
    }
  }

  async resetSelection() {
    await this.setState({ selectedTokensForClaim: [] });
  }

  render() {
    return (
      <div >
        <section className="section-bg">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="collective_main">
                  <div className="collective_title">Collective 168</div>
                  <div className="collective_des">is a media and technology company in the crypto<br />
                    assets space, with a goal to drive value to our collectors<br /> in the long run. Our utilities are designed to be sustainable<br /> beyond the normal roadmap.</div>
                  <div className="collective_tag">Collective 168 Is Founded With Three Pillars In Mind.</div>
                  <ul className="piller_mind">
                    <li>
                      <img src="images/aesthetics.png" />
                      <h4>Aesthetics</h4>
                    </li>
                    <li>
                      <img src="images/scarcity.png" />
                      <h4>Scarcity</h4>
                    </li>
                    <li>
                      <img src="images/utility.png" />
                      <h4>Utility</h4>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="wallet_box">
                  <div className="mint_title">Mint Price</div>
                  <div className="mint_value">{perTokenAmountEth} ETH</div>
                  <div className="mint_title">Max Mint Per Wallet</div>
                  <div className="mint_value">{maxMintAmount}</div>

                  {this.state.isEnabled && this.state.isClaimActive && this.getTokenLists() ? <>
                    <div className="mint_title">GA-1 Tokens</div>
                    <div className="col-lg-12">
                      <div className="row tokens-list">
                        {this.getTokenLists()}
                      </div>
                    </div>
                  </> :""
                    }
                  {this.state.isEnabled ?
                    (this.state.isSaleActive ?
                      <div className="mint_title">You're Minting</div> : '') : ''}

                  {this.state.isEnabled ?
                    this.state.isSaleActive ? (<div className="mint_value">
                      <span style={{ cursor: 'pointer', fontSize: '20px' }} onClick={() => { this.onMinusAmount() }}>
                        <i className="fa fa-minus-circle"></i>
                      </span>
                      <span className="mintAmount"> {this.state.mintAmount} </span>
                      <span style={{ cursor: 'pointer', fontSize: '20px', marginRight: '10px' }} onClick={() => { this.onPlusAmount() }}>
                        <i className="fa fa-plus-circle"></i>
                      </span>
                    </div>) :
                      // (<div className="mint_title">Sale not active</div>)
                      <></>
                    : ''}

                  <div className="mint_title">Total Mint: 3,050<br />Reveal: Instant</div>
                  <div className="mint_footer">Your purchase of our art piece does<br />
                    NOT constitute an investment</div>
                </div>
                {!this.state.isEnabled ?
                  <div className="connect_wallet" onClick={() => this.props.connect()}>
                    CONNECT WALLET
                  </div> : ''}

                {this.state.isEnabled && this.state.isSaleActive ? <div className="connect_wallet" onClick={() => this.mintSubmit()}>
                  MINT
                </div> : ''}

                {this.state.isEnabled && this.state.isClaimActive ? <div className="connect_wallet" onClick={() => this.claimSubmit()}>
                  CLAIM
                </div> : ''}
              </div>
            </div>
          </div>
        </section>

        <section className="advantages">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="advantages_box">
                  <img src="images/aesthetics1.png" />
                  <div className="title">Aesthetics</div>
                  <div className="des">We want people to focus on the
                    aesthetics and enjoy the art piece and not trying to gamble on getting the rarest art, our passing criteria for each art piece would be: "is that something I would love to hang on my wall both in and outside the metaverse?"</div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="advantages_box">
                  <img src="images/scarcity1.png" />
                  <div className="title">Scarcity</div>
                  <div className="des">Our genesis collection only contains 1.5k total mints compared to the common 10k mints. Our future collections are also planned to not go above 5k mints, there will be a few exceptions but it will be for excellent reasons.</div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="advantages_box">
                  <img src="images/utility1.png" />
                  <div className="title">Utility</div>
                  <div className="des">We have a total of 15 Utility. 8 of which are immediately available, the other 7 will be revealed as we progress over the next few months and years.</div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <Link className="findout_btn" to="/3piller" style={{ color: '#fff' }}>Find Out More</Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="comman_title">HERE ARE SOME SAMPLES OF OUR GENESIS WORK.</div>
              </div>
            </div>
          </div>
          <div id="testimonials-list" className="owl-carousel mt-3 owl-loaded owl-drag">
            <OwlCarousel className='owl-theme' loop margin={10}
              center={true}
              items={3}
              dots={true}
              margin={0}
              autoplay={true}
              autoplayTimeout={8500}
              smartSpeed={450}
              responsive={this.responsive}
            >
              <div className="item">
                <img src="images/home_slide1.jpg" />
              </div>
              <div className="item">
                <img src="images/home_slide2.jpg" />
              </div>
              <div className="item">
                <img src="images/home_slide3.jpg" />
              </div>
              <div className="item">
                <img src="images/home_slide1.jpg" />
              </div>
              <div className="item">
                <img src="images/home_slide2.jpg" />
              </div>
              <div className="item">
                <img src="images/home_slide3.jpg" />
              </div>
            </OwlCarousel>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <Link className="findout_btn" to="/gallery" style={{ color: '#fff' }}>GALLERY</Link>
              </div>
            </div>
          </div>
        </section>

        {(this.isAnyTransactionInProgress()) ? (<div id="toast-container" className="toast-top-right">
          <div className="toast toast-info" aria-live="assertive">
            <div className="toast-message">Transaction is in progress.</div>
          </div>
        </div>) : ''}
      </div >
    );
  }
}
export default withGetScreen(Home);
