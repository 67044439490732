import React from 'react';
import { withGetScreen } from 'react-getscreen';
import { Link } from 'react-router-dom';
import $ from 'jquery';

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mobile: this.props.isMobile(),
            tab: this.props.isTablet(),
            account: this.props.prop.account,
        };
    }
    componentDidMount() {
        const select = (el, all = false) => {
            el = el.trim()
            if (all) {
                return [...document.querySelectorAll(el)]
            } else {
                return document.querySelector(el)
            }
        }

        /**
         * Easy event listener function
         */
        const on = (type, el, listener, all = false) => {
            let selectEl = select(el, all)
            if (selectEl) {
                if (all) {
                    selectEl.forEach(e => e.addEventListener(type, listener))
                } else {
                    selectEl.addEventListener(type, listener)
                }
            }
        }

        /**
           * Mobile nav toggle
           */
        // on('click', '.mobile-nav-toggle', function (e) {
        //     console.log("YES:::")
        //     select('#navbar').classList.toggle('navbar-mobile')
        //     this.classList.toggle('bi-list')
        //     this.classList.toggle('bi-x')
        // })

        $(window).resize(function () {
            //resize just happened, pixels changed
            if ($(".mobile-nav-toggle").is(':hidden') && $('#navbar').hasClass('navbar-mobile')) {
                select('#navbar').classList.toggle('navbar-mobile')
            }
        });

        $('.mobile-nav-toggle').on('click', function (e) {

            select('#navbar').classList.toggle('navbar-mobile')
            // this.classList.toggle('bi-list')
            // this.classList.toggle('bi-x')
        })

        /**
         * Mobile nav dropdowns activate
         */
        on('click', '.navbar .dropdown > a', function (e) {
            if (select('#navbar').classList.contains('navbar-mobile')) {
                e.preventDefault()
                this.nextElementSibling.classList.toggle('dropdown-active')
            }
        }, true)
    }

    onMenuClick() {
        if (!$(".mobile-nav-toggle").is(':hidden')) {
            $('.mobile-nav-toggle').trigger('click')
        }
    }

    render() {
        return (
            <div>
                <header id="header" className="fixed-top">
                    <div className="container d-flex align-items-center justify-content-between">
                        <Link to="/" className="logo"><img src="images/logo.png" alt="" className="img-fluid" /></Link>
                        <nav id="navbar" className="navbar">
                            <ul>
                                <li><Link  to="/" className="nav-link active" onClick={() => { this.onMenuClick() }}>HOME</Link></li>
                                <li><Link  to="/3piller" className="nav-link" onClick={() => { this.onMenuClick() }}>3 Pillars</Link></li>
                                <li><Link  to="/gallery" className="nav-link" onClick={() => { this.onMenuClick() }}>Gallery</Link></li>
                                <li><Link  to="/team" className="nav-link" onClick={() => { this.onMenuClick() }}>Team</Link></li>
                                <li><Link  to="/faq" className="nav-link" onClick={() => { this.onMenuClick() }}>FAQ</Link></li>
                                <li>
                                    <a href="https://twitter.com/Collective168" target="_blank" className="nav-link"><img src="images/twitter.png" style={{width:'20px'}} /></a>
                                </li>
                                <li>
                                <a href="https://discord.com/invite/Z7DgHBK4kt" target="_blank" className="nav-link"><img src="images/chat.png" style={{width:'20px'}}/></a>
                                </li>
                            </ul>
                            <i className="bi bi-list mobile-nav-toggle"></i>
                        </nav>
                    </div>
                </header>
            </div>
        );
    }
}
export default withGetScreen(Header);
