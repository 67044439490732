import { Route, HashRouter, Switch } from 'react-router-dom';
import React from 'react';
import Web3 from 'web3';
import ReactTooltip from 'react-tooltip';
import $ from 'jquery';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

import Home from './components/Home';
import Header from './components/Header';
import Footer from './components/Footer';
import Piller from './components/Piller';
import Gallery from './components/Gallery';
import Team from './components/Team';
import Faq from './components/Faq';
import Modal from './components/modal';
import { LIST_ADDRESS_MINTV1_RINKBY, LIST_ADDRESS_MINTV1_MAIN, LIST_ABI_MINTV1, LIST_ADDRESS_MINTV2_RINKBY, LIST_ADDRESS_MINTV2_MAIN, LIST_ABI_MINTV2 } from './config'

const minMintAmount = 1;
const maxMintAmount = 5;
const perTokenAmountEth = 0.04;
class App extends React.Component {
  constructor() {
    super();
    this.state = {
      web3: null,
      networkType: null,
      chainId: null,
      account: null,
      isEnabled: false,
      isConnecting: false,
      optionType: '',
      LIST_ADDRESS_MINT: null,
      mintWeb3InstV1: null,
      mintWeb3InstV2: null,
      canShow: true,
      popupVisible: true,
      isTerm1Checked: false,
      isTerm2Checked: false,
    };

    toastr.options = {
      // positionClass: 'toast-top-full-width',
      hideDuration: 300,
      timeOut: 3000,
    };
  }

  async connectToMetaMaskNetwork(type) {
    if (this.state.popupVisible || !(this.state.isTerm1Checked && this.state.isTerm2Checked)) {
      return;
    }
    if (this.state.isConnecting) {
      return;
    }
    this.setState({ isConnecting: true });
    if (window.ethereum) {
      await window.ethereum.enable().then((err, res) => {
        console.log("Res")
      });
      this.state.web3 = new Web3(window.ethereum);
      window.ethereum.on('networkChanged', (chainId) => {
        // handle the new network
        // document.location.reload();
        this.setConfig();
      });
      window.ethereum.on('chainChanged', (chainId) => {
        // handle the new network
        // document.location.reload();
        this.setConfig();
      });
      window.ethereum.on('accountsChanged', (chainId) => {
        // handle the new network
        document.location.reload();
      });
      window.ethereum.on('disconnect', (chainId) => {
        document.location.reload();
      });
      try {
        // console.log("YES:::")
        this.setState({ optionType: type });
        await this.setConfig();
      } catch (e) {
        // User has denied account access to DApp...
        // console.log("ERROR:::::::::::::::::::::", e)
        await this.setState({ isEnabled: false });
      }
    } else if (window.web3 && window.web3.currentProvider) {
      // console.log("DONE::")
      window.web3.on('networkChanged', (chainId) => {
        // handle the new network
        // document.location.reload();
        this.setConfig();
      });
      window.web3.on('chainChanged', (chainId) => {
        // handle the new network
        // document.location.reload();
        this.setConfig();
      });
      window.web3.on('accountsChanged', (chainId) => {
        // handle the new network
        document.location.reload();
      });
      window.web3.on('disconnect', (chainId) => {
        document.location.reload();
      });
      this.state.web3 = new Web3(window.web3.currentProvider);
      try {
        this.setState({ optionType: type });
        await this.setConfig();
      } catch (e) {
        // User has denied account access to DApp...
        // console.log("ERROR:::::::::::::::::::::")
        await this.setState({ isEnabled: false, isConnecting: false });
        this.changesReflactor();
      }
    } else {
      await this.setState({ isEnabled: false, isConnecting: false });
      this.changesReflactor();
    }
  }

  async setConfig() {
    // console.log("Network changed")
    const Id = await this.state.web3.eth.net.getNetworkType();
    const chainId = await this.state.web3.eth.getChainId();
    const accounts = await this.state.web3.eth.getAccounts();
    this.state.web3.eth.defaultAccount = accounts[0];
    this.configNetwork(Id, chainId, accounts[0]);
  }

  async configNetwork(Id, chainId, account) {
    if (chainId != 1 && chainId != 4) {
      toastr.info('Wrong network choosen.');
      await this.setState({
        isEnabled: false,
        isConnecting: false,
      });
      this.changesReflactor();
      return;
    } else if (chainId == 1 || chainId == 4) {
      let LIST_ADDRESS_MINTV1 = null;
      let LIST_ADDRESS_MINTV2 = null;
      if (chainId == 1) {
        LIST_ADDRESS_MINTV1 = LIST_ADDRESS_MINTV1_MAIN;
        LIST_ADDRESS_MINTV2 = LIST_ADDRESS_MINTV2_MAIN;
      } else if (chainId == 4) {
        LIST_ADDRESS_MINTV1 = LIST_ADDRESS_MINTV1_RINKBY;
        LIST_ADDRESS_MINTV2 = LIST_ADDRESS_MINTV2_RINKBY;
      }
      let mintWeb3InstV1 = new this.state.web3.eth.Contract(LIST_ABI_MINTV1, LIST_ADDRESS_MINTV1)
      let mintWeb3InstV2 = new this.state.web3.eth.Contract(LIST_ABI_MINTV2, LIST_ADDRESS_MINTV2);
      await this.setState({
        isEnabled: true,
        isConnecting: false,
        networkType: Id,
        chainId: chainId,
        account: account,
        LIST_ADDRESS_MINTV1: LIST_ADDRESS_MINTV1,
        LIST_ADDRESS_MINTV2: LIST_ADDRESS_MINTV2,
        mintWeb3InstV2: mintWeb3InstV2,
        mintWeb3InstV1: mintWeb3InstV1
      });
      this.changesReflactor();
    } else {
      this.changesReflactor();
    }
  }

  async changesReflactor() {
    await this.setState({ canShow: false });
    await this.setState({ canShow: true });
  }

  closeTermsPopup() {
    if(!(this.state.isTerm1Checked && this.state.isTerm2Checked)){
      toastr.error("Please accept Terms & Conditions.")
      return;
    }
    this.setState({
      popupVisible: false
    });
  }

  async handleCheckTerm1() {
    await this.setState({ isTerm1Checked: !this.state.isTerm1Checked });
  }

  async handleCheckTerm2() {
    await this.setState({ isTerm2Checked: !this.state.isTerm2Checked });
  }

  render() {
    return (

      <HashRouter hashType='slash'>

<Modal
          visible={this.state.popupVisible}
          width="600"
          height="421"
          headerClass=''
          bodyClass='boxBody'
          footerClass=''
          effect="fadeInDown"
        // onClickAway={() => this.closeTermsPopup()}
        >
          <div className="termsbox">
            <div className="titlebar">
              <div className="title">Terms & Conditions</div>
              {/* <div className="iconbtn" onClick={() => { this.closeTermsPopup() }}><i className="fas fa-times-circle"></i></div> */}
              <div style={{ clear: 'both' }}></div>
            </div>

            <div className="inner">
              <div>
                <a href="pdf/Terms of Sales for Collective 168.pdf" target="_blank">1) Terms of Sales</a>
                <br />
                <a href="pdf/Licensing Agreement for Collective 168.pdf" target="_blank">2) IP Licensing Agreement</a>
              </div>
              <br />
              <div style={{ display: 'flex' }}>
                <input type="checkbox" id="term1" style={{ marginTop: '3px' }} onChange={() => { this.handleCheckTerm1() }} defaultChecked={this.state.isTerm1Checked} checked={this.state.isTerm1Checked} />
                <label for="term1"> I agree that by making a purchase either on Collective 168's website or any secondary sale, I am agreeing that I made my purchase as an art piece, either for my own use or consumption, not as an investment, and without any expectations of “ profits ” as “ either capital appreciation resulting from the development of the initial investment or a participation in earnings resulting from the use of funds" as specified in Collective 168's Terms of Sales.</label>
              </div>
              <br />
              <div style={{ display: 'flex' }}>
                <input type="checkbox" id="term2" style={{ marginTop: '3px' }} onChange={() => { this.handleCheckTerm2() }} defaultChecked={this.state.isTerm2Checked} checked={this.state.isTerm2Checked} />
                <label for="term2">I agree to the IP Licensing Agreement.</label>
              </div>
              <div style={{ clear: 'both' }}></div>
              <br />
              <br />
              <div style={{ display: 'flex' }}>
                <button className="confirm-btn" onClick={() => { this.closeTermsPopup() }}>Confirm</button>
              </div>
            </div>
          </div>
        </Modal>
        <Header prop={this.state}></Header>

        {this.state.canShow ? (
          <Switch>
            <Route exact path='/' render={(props) => (<Home prop={this.state} connect={() => { this.connectToMetaMaskNetwork(''); }}></Home>)}></Route>
            <Route exact path='/3piller' render={(props) => (<Piller prop={this.state}></Piller>)}></Route>
            <Route exact path='/gallery' render={(props) => (<Gallery prop={this.state}></Gallery>)}></Route>
            <Route exact path='/team' render={(props) => (<Team prop={this.state}></Team>)}></Route>
            <Route exact path='/faq' render={(props) => (<Faq prop={this.state}></Faq>)}></Route>
          </Switch>
        ) : (
          ''
        )}
        <Footer prop={this.state}></Footer>
      </HashRouter>
    );
  }
}

export default App;
