import React from 'react';
import { withGetScreen } from 'react-getscreen';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";

class Faq extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile: this.props.isMobile(),
      tab: this.props.isTablet(),
      account: this.props.prop.account,
    };
  }
  componentDidMount() { 
    $('html, body').animate({
      scrollTop: 0
    }, 100);
  }

  render() {
    return (
      <>
        <section className="key_info_main">
          <div className="container">
            <div className="comman_title">Drop #2 Key Information</div>
            <div className="row mt-4">
              <div className="col-lg-4 mt-4">
                <div className="subkey_title">Release Date</div>
                <div className="subkey_info">Stealth</div>
              </div>
              <div className="col-lg-4 mt-4">
                <div className="subkey_title">Totle No Of Token</div>
                <div className="subkey_info">3,050</div>
              </div>
              <div className="col-lg-4 mt-4">
                <div className="subkey_title">Token Withheld From Sale</div>
                <div className="subkey_info">50</div>
                <div className="subkey_info sub_info">(Giveaway,Marketing And Team)</div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-lg-4 mt-4">
                <div className="subkey_title">Price Per Token</div>
                <div className="subkey_info">0.04 ETH</div>
              </div>
              <div className="col-lg-4 mt-4">
                <div className="subkey_title">Token Type</div>
                <div className="subkey_info">ERC -721</div>
              </div>
              <div className="col-lg-4 mt-4">
                <div className="subkey_title">Blockchain</div>
                <div className="subkey_info">Ethereum</div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-lg-4 mt-4">
                <div className="subkey_title">File Hosting</div>
                <div className="subkey_info">IPFS</div>
              </div>
              <div className="col-lg-4 mt-4">
                <div className="subkey_title">Royalty Fees</div>
                <div className="subkey_info">5%</div>
              </div>
              <div className="col-lg-4 mt-4">
                <div className="subkey_title">Licensing</div>
                <div className="subkey_info">Commercial</div>
                <div className="subkey_info sub_info">Plus % Royalites Due To Token Owner</div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-lg-4 mt-4"></div>
              <div className="col-lg-4 mt-4">
                <div className="subkey_title">Reveal Type</div>
                <div className="subkey_info">Instant</div>
              </div>
              <div className="col-lg-4 mt-4"></div>
            </div>
          </div>
        </section>


        <section className="faq_main">
          <div className="container">
            <div className="comman_title">FAQ</div>
            <div className="row">
              <div className="col-lg-12 mt-4">
                <div className="faq_category">About Us</div>
                <Accordion>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <span className="text-black font-bold">
                          Why Support Us?
                    </span>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p className="text-black">
                        {/* - Collector focus - We are aiming to provide value for
                    holders over the long term. <br /> */}
                        <br />
                    - Community first - As you can see from the ETH split, 70%
                    and more is given back to the NFT community in multiple
                    ways. <br />
                        <br />
                    - Great art pieces - We love all of those generated pieces,
                    we've specifically engineered it so all pieces looks
                    amazing.
                    <br />
                        <br />
                    - Transparency - I've showed my face, you who am I. The rest
                    of the team will also make themselves known as we progress.
                    <br />
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <span className="text-black font-bold">
                          {" "}
                      What Does 168 Mean?
                    </span>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p className="text-black">
                        The number "168" is use for auspicious purposes. The
                        pronouciation of the number 168 in Cantonese sounds like
                        "road to prosperity", hence it's a popular number in parts
                        of Asia.
                  </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>

                <div className="faq_category mt-4">About The Drops</div>
                <div className="faq-bg  text-left p-8">
                  <Accordion>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <span className="text-black font-bold">
                            {" "}
                       What Are The Perks Of Owning A C168 Generative NFT?
                     </span>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p className="text-black">
                        We have multiple perks and more to be unlocked, check out <Link to="/3piller"> our Utility </Link> section over on 3 Pillars.
                   </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <span className="text-black font-bold">
                            {" "}
                       When Was This Project Started?
                     </span>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p className="text-black">
                          The idea of selling digital art from Asia was conceived
                          around 6 years ago. The NFT project was started in July
                          2021.
                   </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <span className="text-black font-bold">
                            {" "}
                       Why Did You Choose Generative Art Over Avatars?
                     </span>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p className="text-black">
                          Yes, we know avatars are more popular and there's more money
                          to be made there. But we prefer generative art, it gives us
                          emotions of peace and tranquility when viewing the generated
                          art works. As such we want to create generative art that is
                          great to look at.
                   </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <span className="text-black font-bold"> Wen Rug?</span>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p className="text-black">
                          Fortunately, we have no interest in rugging, we see long
                          term, we want this project to last for years, eventually
                          turning into a self sustaining organization via the DAO. If
                          you are really interested but afraid of us rugging, we can
                          get on a video call you can ask me anything.
                   </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <span className="text-black font-bold"> Wen Moon?</span>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p className="text-black">
                          We should set our expectations right from the beginning. We
                          don't expect the floor to gain a few thousand percent within
                     the first few hours or days. <br />
                          <br />
                     If that's your expectations, we rather you don't mint and
                     give other serious collectors who appreciate the art a
                     chance.
                     <br />
                          <br />
                     Seeing how most project floor dip right after reveal, we
                     should reasonably expect the same thing to happen until
                     flippers let go of their entire holdings and the pieces are
                     in the hands of real collectors.
                     <br />
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <span className="text-black font-bold">
                            {" "}
                       Will We Be Ban For Asking Legit Questions On Discord?
                     </span>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p className="text-black">
                          Nope, you will not be banned for asking legit questions, in
                          fact we welcome all legit questions on your fears,
                          uncertainty, and doubts and if I cannot answer any of your
                          questions to your satisfaction, feel free not to mint any.
                     <br /> <br /> Of course, if you run around screaming: "this
                     is a scam" without any proof, you will be ban.
                     <br /> <br />
                     If you were accidentally banned for legit questions, please
                     message an Admin to get back in
                   </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <span className="text-black font-bold">
                            {" "}
                       How Many Drops Are There In Total?
                     </span>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p className="text-black">
                          This is a secret but we are always working on the next drop.
                   </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <span className="text-black font-bold">
                            {" "}
                       Will You Be Using Your Own Contract?
                     </span>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p className="text-black">
                          Yup, we will be writing our own contract as opposed to using
                          Open Sea Shop Front.
                   </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <span className="text-black font-bold">
                            {" "}
                       Will You Be Using Any Rarity Tools?
                     </span>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p className="text-black">
                          Nope, we don't see a point in using any type of rarity
                          calculator. Rarer doesn't mean it looks better, furthermore
                          every piece is rare, it's a 1/1 of a collection. It is
                          statistically impossible to recreate the exact same piece
                     ever again even with the exact same settings. <br /> <br />
                     We are focusing more on the aesthetics, making each piece
                     look gorgeous. If you like how a piece looks, buy it, if the
                     piece doesn't fit your taste, sell or trade it with someone
                     who loves it.
                     <br />
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <span className="text-black font-bold">
                            {" "}
                       How Much It Cost To Mint?
                     </span>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p className="text-black">
                          0.04 eth.
                     <br />
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <span className="text-black font-bold">
                            {" "}
                       Why Is The Mint Price So Low?
                     </span>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p className="text-black">
                          Originally we want to release the mint at 0.10, but common
                          sense prevail and we think it's better to start off with a
                          lower mint price (0.04 eth) to give more people a chance to
                          buy into the project, especially since the price of ETH has
                          gone up. Secondly it allows more chances of secondary sales
                          at higher prices allowing our early supporters to have a
                          higher profit.
                     <br />
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <span className="text-black font-bold">
                            {" "}
                       How Many Tokens Are There For The First Drop?
                     </span>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p className="text-black">
                          1,550 tokens in total. 50 will be reserved for marketing,
                          giveaways, etc.
                     <br /> <br /> While the 50 tokens are kept by the team, they
                     are excluded in the givebacks, raffles, and giveaways.
                   </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <span className="text-black font-bold">
                            {" "}
                       Why Do You Cap The Max Mint Per Wallet At 5?
                     </span>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p className="text-black">
                          We prefer to have a more diverse community rather than a
                          small group owning majority of the art pieces. If you are
                          rich in ETH, we would kindly ask you to consider to not mint
                          more than 5 in total by using your other wallets unless you
                          are giving them away.
                     <br />
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <span className="text-black font-bold">
                            {" "}
                       Who Owns The IP?
                     </span>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p className="text-black">
                          The ownership and IP rights belongs to each NFT holder, you
                          can make derivatives with it. If you get rich from it, do
                          consider donating some to charities. If you don't know which
                          charities to go for you can send it to us, we will send it
                          to the charities that we've selected.
                     <br />
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <span className="text-black font-bold">
                            {" "}
                       Do You Have A Honorary Collection ?
                     </span>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p className="text-black">
                          Yes, we will have an honorary collection for the genesis
                          drop. Instead of pre-minting those NFTs, we will let the
                          members choose exactly what they want based on the possible
                          permutations, custom colors, exact number of shapes, any of
                          the 8 shapes. And we will generate until the member is happy
                          with the piece before minting it.
                     <br />
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <span className="text-black font-bold">
                            {" "}
                       Can I Opt Out Of Utilities?
                     </span>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p className="text-black">
                          Yes, just let us know, we can remove your wallet from your
                          chosen utility.
                     <br />
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </div>
                <div className="faq_category mt-4">About The Donations ?</div>
                <div className="faq-bg  text-left p-8">
                  <Accordion>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <span className="text-black font-bold">
                            {" "}
                       Which Charities Are You Donating To?
                     </span>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p className="text-black">
                          <span className="font-bold">Charity #1</span> <br />
                          <span className="font-bold"> Blue Dragon Children's Foundation </span><br />
                          <a href="https:www.bluedragon.org/who-we-are/our-story/">https:www.bluedragon.org/who-we-are/our-story/</a> <br />
                          <a href=" https:twitter.com/BlueDragonVN"> https:twitter.com/BlueDragonVN</a> <br />
                     Some background on them: <br />
                     Today, Blue Dragon’s centre remains one of the few safe
                     spaces for homeless children in Hanoi, where they can access
                     teachers, psychologists, social workers and lawyers, take
                     part in youth development activities, and have healthy
                     meals. Blue Dragon’s anti-trafficking work started in 2005,
                     when they rescued a 13-year-old boy trafficked to work on
                     the streets of Ho Chi Minh City. As of June 30, 2019, they
                     have rescued 403 children from forced labour, the youngest
                     being 11 years old. In 2007, Blue Dragon rescued six
                     Vietnamese girls who had been trafficked to China and
                     enslaved in a brothel. As of June 30, 2019, Blue Dragon has
                     rescued 464 girls and women from sex trafficking. From their
                     small beginnings, Blue Dragon has become a force for change
                     around Vietnam, serving 1,500 children and young people
                     while advocating for law and policy reform. Why Blue Dragon?{" "}
                          <br />
                     I've been looking at Blue Dragon and what they have been
                     doing since 2 years ago. It hits close to my personal cause
                     of looking to build a shelter for battered women. So far
                     they have indeed been doing great work in Vietnam helping
                     the kids and rescuing young girls from brothels.<br /><br />
                          <span className="font-bold">Charity #2{" "}</span>
                          <br />
                          <span className="font-bold">Saigon Children’s Charity CIO</span> <br />
                          <a href="https:www.saigonchildren.com">https:www.saigonchildren.com</a> <br />
                          <a href=" https:twitter.com/saigonchildren "> https:twitter.com/saigonchildren </a><br />
                     Some background on them: <br />
                     Saigon Children’s Charity CIO (saigonchildren) was founded
                     in 1992 to help disadvantaged children get an education and
                     a fairer start in life. Saigonchildren works exclusively
                     across the whole of Vietnam to overcome barriers to
                     education for children, including poverty, geography,
                     knowledge and disability. In late 2018 they received The
                     Certificate of Merit from the Prime Minister of Vietnam in
                     recognition of our work improving access to education and
                     contributing to Vietnam’s economic development. Why Saigon
                     Children's Charity? <br />
                     Saigon has been hit hard in the latest Covid wave, many
                     families are without basic food and water. Saigon Children
                     helps them out by sending them money if they are able to go
                     out and purchase their own food, or they will deliver food
                     for the families if they are not allowed to leave the house.
                     <br /><br />
                          <span className="font-bold"> Charity #3<br /> One Tree Planted </span><br />
                          <a href=" https:onetreeplanted.org"> https:onetreeplanted.org</a> <br />
                          <a href="https:twitter.com/onetreeplanted">https:twitter.com/onetreeplanted</a> <br />
                     Some background on them: <br />
                     Trees help clean the air we breathe, filter the water we
                     drink, and provide habitat to over 80% of the world's
                     terrestrial biodiversity. <br />
                     Forests provide jobs to over 1.6 billion people, absorb
                     harmful carbon from the atmosphere, and are key ingredients
                     in 25% of all medicines. Have you ever taken an Aspirin? It
                     comes from the bark of a tree! Why One Tree Planted <br />
                     Another personal pet peeve of mine is deforestation,
                     especially illegal and irresponsible deforestation. One Tree
                     Planted allows us to replant the trees and help offset the
                     carbon that was created during our minting
                     <br />
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </div>

              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
export default withGetScreen(Faq);
