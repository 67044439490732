import React from 'react';
import { withGetScreen } from 'react-getscreen';
import $ from 'jquery'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

class Gallery extends React.Component {
    responsive = {
        0: {
            items: 1
        },
        768: {
            items: 2
        },
        1170: {
            items: 3
        }
    }
    constructor(props) {
        super(props);
        this.state = {
            mobile: this.props.isMobile(),
            tab: this.props.isTablet(),
            account: this.props.prop.account,
        };
    }
    componentDidMount() {
        $('html, body').animate({
            scrollTop: 0
        }, 100);
    }

    render() {
        return (
            <div>
                <section className="gallery_main">
                    <div className="container">
                        <div className="comman_title" style={{ fontSize: '25px' }}>HERE ARE SOME SAMPLES OF</div>
                        <div className="comman_title" style={{ fontSize: '35px' }}>OUR GENESIS COLLECTION.</div>
                        <div className="row mt-5">
                            <div className="col-md-12">
                                <OwlCarousel className='owl-theme' loop margin={10}
                                    center={true}
                                    navText={["<div class='nav-button owl-prev'>‹</div>", "<div class='nav-button owl-next'>›</div>"]}
                                    items={3}
                                    dots={false}
                                    margin={0}
                                    autoplay={true}
                                    autoplayTimeout={8500}
                                    smartSpeed={450}
                                    responsive={this.responsive}
                                    nav>
                                    <div className="item">
                                        <img src="images/gallery/Collection1-1.jpg" />
                                    </div>
                                    <div className="item">
                                        <img src="images/gallery/Collection1-2.jpg" />
                                    </div>
                                    <div className="item">
                                        <img src="images/gallery/Collection1-3.jpg" />
                                    </div>
                                    <div className="item">
                                        <img src="images/gallery/Collection1-4.jpg" />
                                    </div>
                                    <div className="item">
                                        <img src="images/gallery/Collection1-5.jpg" />
                                    </div>
                                </OwlCarousel>
                            </div>
                        </div>
                        {/* <div className="row">
                            <div className="col-lg-12">
                                <div className="findout_btn">Load More</div>
                            </div>
                        </div> */}
                    </div>
                </section>

                <section>
                    <div className="container">
                        <div className="comman_title" style={{ fontSize: '25px' }}>HERE ARE SOME SAMPLES OF</div>
                        <div className="comman_title" style={{ fontSize: '35px' }}>OUR SECOND COLLECTION.</div>
                        <div className="row mt-5">
                            <div className="col-lg-12">
                                <OwlCarousel className='owl-theme' loop margin={10}
                                    center={true}
                                    navText={["<div class='nav-button owl-prev'>‹</div>", "<div class='nav-button owl-next'>›</div>"]}
                                    items={3}
                                    dots={false}
                                    margin={0}
                                    autoplay={true}
                                    autoplayTimeout={8500}
                                    smartSpeed={450}
                                    responsive={this.responsive}
                                    nav>
                                    <div className="item">
                                        <img src="images/gallery/Collection2-1.jpg" />
                                    </div>
                                    <div className="item">
                                        <img src="images/gallery/Collection2-2.jpg" />
                                    </div>
                                    <div className="item">
                                        <img src="images/gallery/Collection2-3.jpg" />
                                    </div>
                                    <div className="item">
                                        <img src="images/gallery/Collection2-4.jpg" />
                                    </div>
                                    <div className="item">
                                        <img src="images/gallery/Collection2-5.jpg" />
                                    </div>
                                </OwlCarousel>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        );
    }
}
export default withGetScreen(Gallery);
