import React from 'react';
import { withGetScreen } from 'react-getscreen';

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mobile: this.props.isMobile(),
            tab: this.props.isTablet(),
            account: this.props.prop.account,
        };
    }
    componentDidMount() { }

    render() {
        return (
            <div>
                <section className="footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="footer_logo"><img src="images/logo.png" /></div>
                                <ul className="footer_btn">
                                    <li><a href="pdf/Terms and Conditions for Collective 168.pdf" target="_blank">Terms And Condition</a></li>
                                    <li><a href="pdf/Privacy Policy for Collective 168.pdf" target="_blank">Privacy Policy</a></li>
                                </ul>
                                <div className="clear"></div>
                                <div className="copyright">Copyright © 2021 - Collective 168. All Rights Reserved.</div>
                            </div>
                            <div className="col-lg-6">
                                <ul className="social_media">
                                <li>
                                    <a href="https://twitter.com/Collective168" target="_blank" className="nav-link" style={{padding:'0'}}><img src="images/twitter.png" style={{width:'25px'}} /></a>
                                </li>
                                <li>
                                <a href="https://discord.com/invite/Z7DgHBK4kt" target="_blank" className="nav-link"  style={{padding:'0'}}><img src="images/chat.png" style={{width:'25px'}}/></a>
                                </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        );
    }
}
export default withGetScreen(Footer);
