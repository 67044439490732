import React from 'react';
import { withGetScreen } from 'react-getscreen';
import $ from 'jquery';

class Team extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mobile: this.props.isMobile(),
            tab: this.props.isTablet(),
            account: this.props.prop.account,
        };
    }
    componentDidMount() {
        $('html, body').animate({
            scrollTop: 0
        }, 100);
    }

    render() {
        return (
            <section className="team_main">
                <div className="container">
                    <div className="comman_title">TEAM</div>
                    <div className="row">
                        <div className="col-lg-4 col-sm-6 col-xs-6">
                            <div className="team_card mt-4">
                                <img src="images/shahroz_khan.png" />
                                <div className="team_card_title">
                                    <div className="name">Shahroz Khan</div>
                                    <div className="designation">Web UI/UX Designer</div>
                                </div>
                                <div className="team_card_social">
                                    <a href="https://www.behance.net/shehrozali722" target="_blank" className="nav-link"><img src="images/behance.png" /></a>
                                </div>
                                <div className="clear"></div>
                                <div className="team_card_des">
                                    Senior Website, Mobile App UI UX, Graphic Designer, and Laravel/ CodeIgnitor PHP Developer having more than 10+ years of experience in Website Design and Development.
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-6 col-xs-6">
                            <div className="team_card mt-4">
                                <img src="images/sv.png" />
                                <div className="team_card_title">
                                    <div className="name">SV</div>
                                    <div className="designation">Web & Solidity Developer</div>
                                </div>
                                <div className="clear"></div>
                                <div className="team_card_des">
                                    Blockchain solution architect and developer for Ethereum and HyperLedger. Experienced in writing Ethereum and HyperLedger. Experienced in writing contracts for NFTs.
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-6 col-xs-6">
                            <div className="team_card mt-4">
                                <img src="images/dmitriy.png" />
                                <div className="team_card_title">
                                    <div className="name">Dmitriy</div>
                                    <div className="designation">Technical Artist</div>
                                </div>
                                <div className="clear"></div>
                                <div className="team_card_des">
                                    Full Stack Web Developer, And Software Developer. Excellent skills in web development, 3D modeling and random art generation.
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-6 col-xs-6">
                            <div className="team_card mt-4">
                                <img src="images/karleken.png" />
                                <div className="team_card_title">
                                    <div className="name">@TheKarleken</div>
                                    <div className="designation">Everything Else</div>
                                </div>
                                <div className="team_card_social">
                                    <a href="https://twitter.com/TheKarleken" target="_blank" className="nav-link"><img src="images/twitter_team.png" /></a>
                                </div>
                                <div className="clear"></div>
                                <div className="team_card_des">
                                    David. Singaporean in Vietnam, former EIR at a Saigon Startup Accelerator. Started a T-shirt printing company 15 years ago, and a high end chocolate subscription company 9 years ago.
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-6 col-xs-6">
                            <div className="team_card mt-4">
                                <img src="images/muhmad.png" />
                                <div className="team_card_title">
                                    <div className="name">Muhammad Arslan</div>
                                    <div className="designation">UI/UX Designer</div>
                                </div>
                                <div className="team_card_social">
                                    <a href="https://www.behance.net/arslanranjha1" target="_blank" className="nav-link"><img src="images/behance.png" /></a>
                                </div>
                                <div className="clear"></div>
                                <div className="team_card_des">
                                    Website and Mobile Ui/Ux Designer, Graphic Designer with 3+ years of experience.
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-6 col-xs-6">
                            <div className="team_card mt-4 team_card_email">
                                <div className="email_main">
                                    <div className="team_card_email_img">
                                        <img src="images/email.png" />
                                    </div>
                                    <div className="clear"></div>
                                    <div className="email_text">Reach out through discord and twitter <br /> for more information. </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        );
    }
}
export default withGetScreen(Team);