import React from 'react';
import { withGetScreen } from 'react-getscreen';
import $ from 'jquery';

class Piller extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile: this.props.isMobile(),
      tab: this.props.isTablet(),
      account: this.props.prop.account,
    };
  }
  componentDidMount() {
    $('html, body').animate({
      scrollTop: 0
    }, 100);
   }

  render() {
    return (
      <>
        <section className="aesthetics_main">
          <div className="container">
            <div className="comman_title">AESTHETICS</div>
            <div className="row mt-4">
              <div className="col-lg-7">
                <div className="comman_title" style={{ fontSize: '45px' }}>Our art pieces are</div>
                <div className="aesthetics_des" >
                  designed to be wonderful to look at and great to add to your collection even without any additional utility, the plan is to improve the quality of art of the so called "common rarity" so it looks just as awesome as a "rare".
                  <br /><br />
                    We want people to focus on the aesthetics and enjoy the art piece and not trying to gamble on getting the rarest art, our passing criteria for each art piece would be: "is that something I would love to hang on my wall both in and outside the metaverse?
                    <br /><br />
                    Some pieces may speak to you more, totally captivating your attention, that's the piece you want to buy and add to your collection regardless of the statistical rarity.
                </div>
              </div>
              <div className="col-lg-5">
                <img src="images/aesthetics_1.jpg" style={{ width: '100%' }} />
              </div>
            </div>
          </div>
        </section>

        <section className="phase_main">
          <div className="container">
            <div className="comman_title">We plan to roll out our<br />
                      project in multiple phases:</div>
            <div className="row">
              <div className="col-lg-12">
                <div className="phase_title">In phase 1:</div>
                <div className="phase_des">
                  Art Block type of generative art. We are working work a small group of developers and artist that we know from outside the NFT world to push out the art pieces.
                    </div>
              </div>

              <div className="col-lg-12">
                <div className="phase_title">In phase 2:</div>
                <div className="phase_des">
                  We are looking to push out more 1/1 art pieces and/or small collections of around 3 to 10 pieces. This could be:<br /><br />
                  • Physical 1/1 pieces and collections that we purchased and subsequently scan and resell with the artist's permission. We may ship the actual physical art to the buyer if it's feasible (ie; not a marble statue weighing 1 ton). It will be noted for each piece being sold.<br /><br />
                  • Physical 1/1 pieces and collections that the artist consigned with us and they receive money from the sales and subsequent royalties.<br /><br />
                  • Digital 1/1 pieces and collections that we purchase from the artists.<br /><br />
                  For the physical pieces we will be looking to buy art from artists currently located in Vietnam and Thailand.
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="scarcity_main">

          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="col-lg-12"><div className="comman_title">SCARCITY</div></div>
              </div>
            </div>
          </div>

          <div className="scarcity_mint_main">
            <div className="scarcity_mint_val">3,050</div>
            <div className="scarcity_mint_des">MINTS ONLY</div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="scarcity_des">
                  Our genesis collection contains only 1,550 total mints compared to the common 10k mints. While our second collection features only 3,050 mints. Our future collections are also planned to not go above 5k mints, there will be a few exceptions but it will be for excellent reasons.<br /><br />
                  For phase two when we are pushing out more 1/1 projects and/or small collections, it will obviously be even more scarce.
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="utility_main">
          <div className="container">
            <div className="comman_title">UTILITY</div>
            <div className="log-lg-12">
              <div className="utility_contain_1">
                We have a total of 15 utility. 8 of which are immediately available, the other 7 will be revealed as we progress over the next few months and years. They are still under research and development and its released is subjected to votes in the DAO and actual feasibility. And as we move forward, if you have ideas on how to add more fun and entertainment for our collectors, hit us up and if we include it in the roadmap, you stand to get a reward.
                <br /><br />
                By making a purchase either on our website or any secondary sale, you are agreeing that you made your purchase as an art piece, either for your own use or consumption, not as an investment, and without any expectations of " profits " as " either capital appreciation resulting from the development of the initial investment or a participation in earnings resulting from the use of funds".
            	</div>
            </div>
            <div className="col-lg-12">
              <ul className="utility_list">
                <li>
                  <img src="images/ip_li.png" />
                  <div><b>IP Licensing:</b> As collectors of our generative art piece owns the copyright and IP, each major collector will be entered into a IP Licensing contract with Collective168 automatically, but they can choose to opt out. This will allow Collective168 to be able to list and sell the pieces through partners such as ecommerce websites like ETSY or Shopify that may want to sell our physical prints. Along with future projects, brands, side projects that uses the same IP.
                  </div>
                </li>
                <li>
                  <img src="images/dao.png" />
                  <div><b>DAO Token:</b> Holders of Genesis tokens and other subsequent drops will be able to redeem + gas for a DAO token at a later date. The token gives the owner governance over C168 project, allowing them to suggest new ideas and directions for the project. We will not buy and flip NFTs nor invest into fractional NFTs with the DAO. Do not buy into the DAO token with value appreciation as your goal.
                  </div>
                </li>
                <li>
                  <img src="images/raffle.png" />
                  <div><b>Raffles:</b> Genesis holder gets 2x ticket while gen 2 and subsequent series gets 1 ticket per art piece to win 1/1 art piece, held once a month or more frequent<br /><br />
                  We aim to prioritize purchasing 1/1 from new artists entering into the NFT space to be used in the giveaway, we understand how hard it is to sell your first NFT piece, and how exciting you feel when you do. Extra chances to get pick by us if you hold one of our pieces.<br />
                  </div>
                </li>
                <li>
                  <img src="images/phy_art.png" />
                  <div><b>Physical Art Piece:</b> Either printed on canvas or aluminum. We have access to manufacturing factories especially in Asia and UK.
                  </div>
                </li>
                <li>
                  <img src="images/mint.png" />
                  <div><b>Free Minting: </b>What's an NFT project without free mints and airdrops. Genesis holders will get free mint + gas for Gen 2. Estimated Value Per Eligible Wallet: 0.04 ETH Per Piece.</div>
                    </li>
                <li>
                  <img src="images/charity.png" />
                  <div><b>Donation To Charities:</b>We've picked a few charities for donations from the genesis drop. Future donations to charities will be decided by the DAO. Ideally we would like to donate to charities that helps with the less fortunate. Not many people are able to drop $1,000 to $2,000 on digital art without thinking twice.</div>
                    </li>
                <li>
                  <img src="images/Casino Icon.png" />
                  <div><b>C168 Casino:</b> Collectors of our art pieces is now able to claim free Paint tokens on discord (these are non-tradable tokens and carry zero financial value). You can then use the tokens to play in our discord casino to earn more Paint Tokens . Paint Tokens can then be used to redeem for NFT pieces that we purchased from multiple collections. We will start off smaller rewards and gradually add more interesting ones.</div>
                </li>
                <li>
                  <img src="images/Leaderboard Icon.png" />
                  <div><b>Trade Leaderboard:</b> So You Think You Can Trade? Discord leaderboard for the top trader per month. Rewards are in Paint Tokens and maybe ETH in the future. This will likely be the most challenging flipping you are going to do. Total monthly trade of each trader must be above X volume in etc to qualify. This may be increased at a reasonable rate as we progress. Profitable trade increases the profit counter while selling below the price you bought it for will lower the profit counter. Trades must be opened and closed within the month to be counted; You must buy and sell within the month, buying across multiple months and then selling all at once won't work. You will need to hold at least 1 piece of our art at all times for the tracker to track your sales. If you own 2 and sold 1, your profit will be recorded. If you own 1 and sold 1, our tracker won't be able to see your wallet in the list of holders and hence can't track the sales.</div>
                </li>
              </ul>
            </div>
          </div>
        </section>

        <section className="royle_main">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <img src="images/royalty.png" style={{ width: '100%' }} />
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
export default withGetScreen(Piller);